<template>
  <section class="commissions">
    <div class="commissions__container">
      <h2 class="commissions__title text-title">Commissions</h2>
      <p class="commissions__description text-description">
        browngrotta arts regularly works with clients, trade professionals and artists to create
        commissioned works for public, corporate and residential and spaces. The process may involve
        several steps, depending upon the project: 1) preparation of a drawing or maquette for
        approval, 2) color approval for client review, 3) timeline determination, 4) status reports
        and 5) completion of the work, on schedule, as expected.
      </p>
      <BaseButton
        class="commissions__button button--white"
        text="INQUIRE"
        @click="$router.push('/art-advisory/commissions/inquire')"
      ></BaseButton>
    </div>
    <div class="carousel-wrapper">
      <IconButton
        class="carousel__button carousel__button--back button--circle button--white"
        @click="goToSlide('prev')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
      <VueSlickCarousel v-bind="carouselSettings" ref="carousel" @afterChange="changeSlide">
        <div v-for="(slide, index) in slides" :key="index" class="slide">
          <img class="slide__image" :src="slide.image" :alt="slide.text" />
        </div>
      </VueSlickCarousel>
      <IconButton
        class="carousel__button carousel__button--next button--circle button--white"
        @click="goToSlide('next')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
    </div>
    <p class="commissions__slide-description text-description">{{ slideActive.text }}</p>
  </section>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { mapMutations } from "vuex";

export default {
  components: {
    BaseButton,
    IconButton,
    IconArrowTop,
    VueSlickCarousel,
  },
  data() {
    return {
      carouselSettings: {
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        infinite: true,
        centerPadding: "20px",
        variableWidth: true,
      },
      slides: [
        {
          image: require("@/assets/images/services/сommissions/slide_1.jpg"),
          text: "Created, to size, for the Galaxy Hotel in Macau.",
        },
        {
          image: require("@/assets/images/services/сommissions/slide_2.jpg"),
          text: "Tapestries incorporating colors and items with meaning for the collectors.",
        },
        {
          image: require("@/assets/images/services/сommissions/slide_3.jpg"),
          text: "Artist Kiyomi Iwata installs a site-specific commission of silk.",
        },
        {
          image: require("@/assets/images/services/сommissions/slide_4.jpg"),
          text: "A word sculpture made of wire labeled with the collecting corporation’s name.",
        },
        {
          image: require("@/assets/images/services/сommissions/slide_5.jpg"),
          text: "A series of panels made of fiber-wrapped wire, commissioned to brighten a residential space.",
        },
      ],
      slideActive: {},
    };
  },
  head: {
    title: function () {
      return {
        inner: "Commissions browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `browngrotta arts regularly works with clients, trade professionals and artists to create
        commissioned works for public, corporate and residential and spaces. The process may involve
        several steps, depending upon the project: 1) preparation of a drawing or maquette for
        approval, 2) color approval for client review, 3) timeline determination, 4) status reports
        and 5) completion of the work, on schedule, as expected.`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Commissions browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Commissions browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Commissions browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: `browngrotta arts regularly works with clients, trade professionals and artists to create
        commissioned works for public, corporate and residential and spaces. The process may involve
        several steps, depending upon the project: 1) preparation of a drawing or maquette for
        approval, 2) color approval for client review, 3) timeline determination, 4) status reports
        and 5) completion of the work, on schedule, as expected.`,
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Commissions browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: `browngrotta arts regularly works with clients, trade professionals and artists to create
        commissioned works for public, corporate and residential and spaces. The process may involve
        several steps, depending upon the project: 1) preparation of a drawing or maquette for
        approval, 2) color approval for client review, 3) timeline determination, 4) status reports
        and 5) completion of the work, on schedule, as expected.`,
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Commissions browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    this.slideActive = this.slides[0];
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Art Advisory",
        to: "/art-advisory",
        clickable: true,
      },
      {
        title: "Commissions",
        to: "/art-advisory/commissions",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;
      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    changeSlide(slideIndex) {
      this.slideActive = this.slides[slideIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.commissions {
  padding: 23rem 0;
  @media screen and (max-width: $xs) {
    padding: 24rem 0 23rem;
  }
  &__container {
    padding: 0 31.5rem 10.5rem;
    @media screen and (max-width: $xs) {
      padding: 0 25px 12.5rem;
    }
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  &__description {
    font-weight: 100;
    margin-bottom: 2.5rem;
  }
  &__button {
    min-width: 18rem;
  }
  &__slide-description {
    font-weight: 100;
    margin: 0 auto;
    max-width: 60rem;
    text-align: center;
    @media screen and (max-width: $xs) {
      padding: 0 25px;
    }
  }
}

.carousel {
  &-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  &__button {
    width: 7rem;
    height: 7rem;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
    }
    &--next {
      position: absolute;
      right: 10.5rem;
      top: 50%;
      transform: rotate(90deg) translateY(-50%);
      z-index: 1;
    }
    &--back {
      position: absolute;
      left: 10.5rem;
      top: 50%;
      transform: rotate(-90deg) translateY(-50%);
      z-index: 1;
    }
    & &-icon {
      width: 18px;
      height: 12px;
      @media screen and (max-width: $xs) {
        width: 14px;
        height: 8px;
      }
    }
  }
}
.slide {
  margin: 0 5px;
  max-width: 60rem;
  max-height: 60rem;
  @media screen and (max-width: $xs) {
    max-width: 34.5rem;
    max-height: 34.5rem;
  }
  @media screen and (max-width: $xxxs) {
    max-width: 32.5rem;
    max-height: 32.5rem;
  }
  @media screen and (max-width: $xxxxs) {
    max-width: 27rem;
    max-height: 27rem;
  }
}
</style>
